.finalized-proposals {
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: fit-content;
  width: 90vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 0;
}

.finalized-proposals h2 {
  margin-bottom: 20px;
  text-align: center;
}

.finalized-proposals ul {
  list-style: none;
    padding: 0;
    margin: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 2vw;
}

.finalized-proposals li {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 2vw;
  background-color: rgba(0, 0, 0, .5);
  width: 40vw;
  overflow: hidden;
  
}

.finalized-proposals h3 {
  margin: 0 0 10px 0;
  color: white;
}

.finalized-proposals p {
  margin: 15px 0;
  color: white;
  word-wrap: break-word; /* Ensures long words break to fit the container */
  overflow-wrap: break-word; /* Ensures long words break to fit the container */
}

.finalwrapper {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  align-items: top;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.finalwrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

@media (max-width: 768px) {
  .finalized-proposals {
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    color: white;
    border-radius: 1vw;
    box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
    padding: 1vw;
    height: fit-content;
    width: 90vw;
    gap: 1vw;
    background-color: rgba(0, 0, 0, .8);
    transition: opacity 1s ease-in-out;
    border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
    z-index: 0;
  }
  
  .finalized-proposals h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .finalized-proposals ul {
    list-style: none;
      padding: 0;
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 2vw;
  }
  
  .finalized-proposals li {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 2vw;
    background-color: rgba(0, 0, 0, .5);
    width: 70vw;
    overflow: hidden;
    
  }
  
  .finalized-proposals h3 {
    margin: 0 0 10px 0;
    color: white;
  }
  
  .finalized-proposals p {
    margin: 15px 0;
    color: white;
    word-wrap: break-word; /* Ensures long words break to fit the container */
    overflow-wrap: break-word; /* Ensures long words break to fit the container */
  }
  }