.portfolio-page {
  padding: 2vw;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2vw;
  border-color: black;
  border-style: solid;
  border-width: .15vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  text-align: center;
  margin: auto;
  margin-top: 5vw;
  flex-wrap: wrap;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
}

.portpage1 {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.portpage1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}


.portfolio-page h1 {
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  font-size: 3vw;
  margin-bottom: 0vw;
  text-align: center;
}

.portfolio-page h3 {
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-bottom: 2vw;
}

.portfolio-page p {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: auto;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .portfolio-page {
    margin-top: 20vw;
    width: 90vw;
  } 

  .portfolio-page p {
    width: 90vw
  }

  .portfolio-page h1 {
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    font-size: 10vw;
    margin-bottom: 0vw;
    text-align: center;
  }
  
  .portfolio-page h3 {
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    text-align: center;
    margin-bottom: 5vw;
  }

  }