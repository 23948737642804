.gov-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    padding: 1.2vw;
  }
  
  .gov-nav a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
    font-size: 1.2vw;
  }

  .gov-nav2 {
    display: none;
  }
  
  .gov-nav a:hover {
    text-decoration: underline;
  }
  
  .gov-nav button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .gov-nav button:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    .gov-nav {
      display: none;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      justify-content: center;
      align-items: center;
      background-color: black;
      padding: 10px 20px;
    }
    
    .gov-nav a {
      color: white;
      text-decoration: none;
      font-size: 18px;
      margin: 10px;
    }
    
    .gov-nav a:hover {
      text-decoration: underline;
    }
    
    .gov-nav button {
      background-color: #4CAF50;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 16px;
      border-radius: 5px;
      margin: 5px;
    }
    
    .gov-nav button:hover {
      background-color: #45a049;
    }

    .gov-nav2 {
      display:flex;
      background-color: rgba(0, 0, 0, 1);
      height: 18vw;
      width: 100vw;
      position: relative;
      top: 0;
      z-index: 1000;
      align-items: center;
    }
  }