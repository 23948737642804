.oink-data-page {
  display: flex;
  align-items: center;
  color: white;
  border-radius: 1vw;
  padding: 1vw;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.datawrapper {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
}

.datawrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

.oink-data-page h1 {
  margin-bottom: 2vw;
}

.oink-data-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 2vw;
  width: 90vw;
  height: auto;
  padding: 1vw;
  border-radius: 9px;
}

.data-box {
  background: black;
  opacity: .8;
  padding: 2vw;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-style: solid;
  border-width: .15vw;
  border-color: black;
}


.data-box h3 {
  margin-bottom: 1vw;
  font-size: 1.5vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
}

.data-box p {
  font-size: 1.3vw;
  font-weight: 800;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
}

.link-item4 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: white;
  border: none;
  border-radius: .5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  font-size: 1.3vw;
  margin-top: 1.5vw;
  width: fit-content;
  align-self: center;
}

@media (max-width: 768px) {
  .datawrapper {
    position: relative;
    background: linear-gradient(270deg, #FFDD28, #3b1818);
    background-size: 100% 100%;
    width: 100vw;
    height: 125vh;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 100;
    margin-bottom: 0vw;
    box-sizing: border-box;
    padding: 2vw;
  }
  
  .datawrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 125vh;
    background: url('/public/oinkpattern6.png') repeat;
    background-size: 25vw;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the background image is behind other content */
  }
  .oink-data-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      width: 95vw;
      border: black;
      padding: 1vw;
      border-radius: 9px;
      height: 20vh;
      position: absolute;
      top: 10vh;
    }

    .meowww {
      width: 100vw;
    }

    .oink-data-page {
      display: flex;
      align-items: center;
      color: white;
      border-radius: 1vw;
      padding: 1vw;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
    

    .data-box {
      background: black;
      opacity: .8;
      padding: 2vw;
      border-radius: 10px;
      text-align: center;
      height: 15vh;
      width: 40vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-style: solid;
      border-width: .15vw;
      border-color: black;
    }
    
    .data-box h3 {
      margin-bottom: 1vw;
      font-size: 5vw;
      color: #61e2ff;
    }
    
    .data-box p {
      font-size: 4vw;
      font-weight: 800;
      text-align: center;
      text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
      
    }

    .link-item4 {
      padding: 0.5vw 1vw;
      background-color: #FFDD28;
      color: white;
      border: none;
      border-radius: 1vw;
      text-decoration: none;
      transition: background-color 0.3s ease-in-out;
      text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
      box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
      text-align: center;
      font-weight: 900;
      font-size: 4vw;
      width: fit-content;
      align-self: center;
      margin-top: 5vw;
    }
  }