body, html {
  height: 100vh;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  z-index: -1; /* Ensure the background is behind other content */
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.yooooo {
  width: 100vw;
}

.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


  

