/* Carousel container styles */
.carousel-container {
  display: flex;
}

.homewrapper {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.ender {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #000000, #000000);
}

.homewrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

.homewrapper2 {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 94vh;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.homewrapper2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 94vh;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}



/* Home page styles */
.home-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 80vh;
  width: 90vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
}

.intro-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 80vh;
  width: 90vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
}

.home-page2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 80vh;
  width: 90vw;
  gap: 1vw;
  background-repeat: repeat;
  background-size: 20vw;
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
  background-color: white;
  margin-top: 0vw;
}

/* Hidden elements */
.hidden {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

/* Visible elements */
.visible {
  opacity: 1;
  z-index: 3;
}

/* Carousel button styles */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
}

.carousel-button.prev {
  left: 1vw;
}

.carousel-button.next {
  right: 1vw;
}

.superpig {
  width: 25vw;
}

.superpig1 {
  width: 25vw;
  border-radius: 2vw;
}

.checkmark {
  color: green;
  margin-left: 5px;
}

.superoink {
  font-size: 1.1vw;
  text-align: center;
  font-weight: 600;
  line-height: 1.6;
  margin: 1vw;
  margin-top: auto;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
}

.superoink3 {
  font-size: 1.1vw;
  text-align: center;
  font-weight: 600;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  color: white;
}

.supertextbox{
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: fit-content;
  width: 60vw;
  overflow: hidden;
}

.supertextbox h3 {
  margin-bottom: 1vw;
  margin-top: 0vw;
  font-size: 3vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.supertext {
  width: 55vw;
  display: flex;
  gap: 1.5vw;
  align-items: center;
  padding: 0vw;
  height: 100%;
  width: 100%;
  margin:auto;
  flex-direction: column;
}

.superbox {
  width: 100%;
  height: 100%;
  border-radius: 2vw;
  overflow: hidden;
  padding: 1vw;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}


.transition {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
}

.skater {
  width: 100vw;
}

.site {
  display: flex;
}

.another-page {
  padding: 2vw;
  color: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 2vw;
  margin-top: 9vw;
  width: 50vw;
}

.txlink {
  margin-top: 1vw;
  padding: 0.5vw 1vw;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 1.5vw;
  text-decoration: none;
}

.another-page h1 {
  color: #A0CEFF;
  font-size: 3vw;
  margin-bottom: .5vw;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.another-page h2 {
  color: white;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 1.5vw;
}

.another-page h3 {
  color: white;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 1.1vw;
}

.another-page h5 {
  color: white;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 1.1vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
}

.another-page h6 {
  color: red;
  text-align: center;
  font-size: 1.1vw;
  margin-bottom: 1vw;
  margin-top: 1vw;
}

.image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  position: relative;
  height: 75vh;
  padding: 1vw;
}

.image-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  position: relative;
  height: 75vh;
  padding: 1vw;
}

.video-box {
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 1vw;
  z-index: 4;
  align-items: center;
  display: flex;
  
  border-radius: 2vw;
}

.video-box iframe {
  width: 100%;
  height: 100%;
  border-radius: 2vw; /* Same border-radius as .video-box */
  display: block;
  border: none;
}

.video-box2 {
  width: 100%;
  height: 100%;
  border-radius: 2vw;
  overflow: hidden;
  padding: 1vw;
  z-index: 4
}

.video-box2 iframe {
  width: 100%;
  height: 100%;
  border-radius: 2vw; /* Same border-radius as .video-box */
  display: block;
  border: none;
}

.text-header {
  font-size: 3vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  margin-top: 0vw;
  margin-bottom: 1vw;
}

.oinkimage {
  width: 30vw; /* Adjusted to fit within the new layout */
  height: auto;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
  margin-top: 0vw;
}

.links-section2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: .5vw;
  margin-top: 2vw;
  margin-bottom: 0vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1vw;
  border-radius: 2vw;
  z-index: 4;
}

.links-section3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 1vw;
  margin-top: 2vw;
  margin-bottom: 0vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1vw;
  border-radius: 2vw;
  z-index: 4
}

.intro-section {
  width: 55vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1vw;
  align-items: center;
  padding: 0vw;
  height: 100%;
  margin: auto;
  border-radius: 2vw;
}

.text-section {
  width: 55vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 1.5vw;
  align-items: center;
  padding: 0vw;
  height: 80vh;
  margin:auto;
}

.text-section2 {
  width: 100vw;
  display: flex;
  gap: 3vw;
  align-items: center;
  padding: 1vw;
  align-items: center;
  height: 100vh;
  justify-content: top;
  margin-top: 12vw;
  flex-direction: column;
}

.oinktext {
  font-size: 1.2vw;
  text-align: center;
  font-weight: 600;
  line-height: 1.6;
  margin: 1vw;
  margin-top: auto;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
}

.link-item2 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 1.2vw;
  border-style: solid;
  border-width: .15vw;
  border-color: black;
  min-width: 7vw;
}

.link-item5 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 1.4vw;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  border-style: solid;
  border-width: .15vw;
  border-color: black;
  margin-bottom: 0vw;
  margin-top: 1vw;
  width: fit-content;
  align-self: center;
}

.text-box3 {
  background-color: rgba(0, 0, 0, .35);
  border-radius: 2vw;
  transition: transform 0.3s;
  width: fit-content;
  border: black;
  border-style: solid;
  border-width: .15vw;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1vw;
}

.text-box4 {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 2vw;
  transition: transform 0.3s;
  width: fit-content;
  border: black;
  border-style: solid;
  border-width: .15vw;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1vw;
}

.text-box3 h3 {
  margin-bottom: 1vw;
  margin-top: 0vw;
  font-size: 1.8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.gamepreview {
  width: 30vw;
  height: auto;
  border-radius: 2vw;
}

.link-item3 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 1.2vw;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  border-style: solid;
  border-width: .15vw;
  border-color: black;
}

.link-item3:hover {
  background-color: #f06;
}

.link-item2:hover {
  background-color: #f06;
}

.text-box {
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: 19vw;
  width: 27vw;
}

.intro-box {
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: 38vh;
  width: 45vw;
}

.intro-box h3 {
  margin-bottom: .5vw;
  font-size: 1.8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.text-box2 {
  opacity: 1;
  transition: transform 0.3s;
  height: 19vw;
  width: 23vw;
}


.text-box h3 {
  margin-bottom: 0vw;
  font-size: 1.8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.text-box2 h3 {
  margin-bottom: 0vw;
  font-size: 1.8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.video-splash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.video {
  width: 100%;
  height: auto;
  animation: 5s forwards; /* Fades out after the video plays */
}

.hidden-content {
  display: block; /* Default to visible on larger screens */
  transition: all 0.3s ease;
}

.visible-content {
  display: block;
  transition: all 0.3s ease;
}

.toggle-button {
  display: none; /* Hide the button on larger screens */
}

.toggle-button2 {
  display: none; /* Hide the button on larger screens */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .home-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: auto;
  width: 100vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
  margin-top: 0vw;
}

.superoink3 {
  font-size: 3vw;
  text-align: center;
  font-weight: 600;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  color: white;
}

.intro-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: auto;
  width: 100vw;
  background-color: rgba(0, 0, 0, .8);
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
  margin-top: 12vw;
  text-align: center;
  margin-bottom: 0vw;
}

.image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: relative;
  height: fit-content;
  padding: 1vw;
}

.text-header {
  font-size: 12vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  margin-top: 0vw;
  margin-bottom: 1vw;
  align-self: center;
}

.home-page2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 80vh;
  width: 100vw;
  gap: 1vw;
  background-repeat: repeat;
  background-size: 20vw;
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
  background-color: white;
  margin-top: 0vw;
}

.intro-section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1vw;
  align-items: center;
  padding: 0vw;
  height: auto;
  margin-top: 0vw;
  border-radius: 2vw;
  margin-bottom: 0vw;
}

.intro-box {
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: auto;
  width: 100vw;
  margin-top: 0vw;
}

.intro-box h3 {
  margin-bottom: .5vw;
  font-size: 8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-top: 0vw;
}

.video-box {
  width: 100vw;
  height: 30vh;
  overflow: hidden;
  padding: 1vw;
  z-index: 4
}

.hidden-content {
  display: none;
  transition: all 0.3s ease;
}

.visible-content {
  display: block;
  transition: all 0.3s ease;
}

.toggle-button {
  display: block;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  padding: 1vw;
  margin: 1vw 0;
  margin-top: 2vw;
  cursor: pointer;
}

.toggle-button2 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  border-style: solid;
  border-width: .15vw;
  border-color: black;
  cursor: pointer;
  display: block;
  background: url('/public/oinkpattern5.png') repeat;
  background-size: 50vw;
}

.oinktext {
  font-size: 3.5vw;
  text-align: left;
  font-weight: 600;
  line-height: 1.6;
  margin: 1vw;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
}

.links-section2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 5vw;
  margin-top: 2vw;
  margin-bottom: 0vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1vw;
  border-radius: 2vw;
  z-index: 4;
  width: 100vw;
}

.link-item2 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  border-style: solid;
  border-width: .15vw;
  border-color: black;
}

.superpig {
  width: 100vw;
  height: auto;
  margin-top: 0vw;
}

.checkmark {
  color: green !important;
  margin-left: 5px;
}

.superoink {
  font-size: 3.5vw;
  text-align: left;
  font-weight: 600;
  line-height: 1.6;
  text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
  
}

.supertextbox{
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  margin-bottom: 0vw;
}

.supertextbox h3 {
  margin-bottom: 1vw;
  margin-top: 0vw;
  font-size: 8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
}

.supertext {
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 0vw;
  height: auto;
  width: 100vw;
  margin-bottom: 0vw;
}

.superbox {
  width: 100vw;
  height: fit-content;
  border-radius: 2vw;
  overflow: hidden;
  padding: 1vw;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0vw;
  margin-bottom: 0vw;
}

.image-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: relative;
  height: auto;
  padding: 1vw;
}

.video-box2 {
  width: 100vw;
  height: 30vh;
  overflow: hidden;
  padding: 1vw;
  z-index: 4
}

.links-section3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 5vw;
  margin-top: 2vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1vw;
  border-radius: 2vw;
  z-index: 4;
  width: 100vw;
}

.superpig1 {
  width: 50vw;
  align-items: center;
  align-self: center;
}

.link-item3 {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  color: black;
  border: none;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  border-style: solid;
  border-width: .15vw;
  border-color: black;
}

.text-section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  align-items: center;
  padding: 0vw;
  height: auto;
  margin:auto;
}

.text-box {
  opacity: 1;
  border-radius: 2vw;
  transition: transform 0.3s;
  height: auto;
  width: 100vw;
}

.text-box h3 {
  font-size: 8vw;
  color: #A0CEFF;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-top: 0vw;
}

.skater {
  width: 100vw;
  height: auto;
}

.homewrapper2 {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.homewrapper2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

.text-box3 {
  background-color: rgba(0, 0, 0, .35);
  border-radius: 2vw;
  transition: transform 0.3s;
  width: fit-content;
  border: black;
  border-style: solid;
  border-width: .15vw;
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1vw;
}



  .another-page {
    padding: 2vw;
    color: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 2vw;
    margin-top: 5vw;
    width: 90vw;
    margin-top: 20vw;
  }
  
  .txlink {
    margin-top: 1vw;
    padding: 0.5vw 1vw;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 5vw;
    text-decoration: none;
  }
  
  .another-page h1 {
    color: #A0CEFF;
    font-size: 10vw;
    margin-bottom: 2vw;
    margin-top: 0vw;
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  
  .another-page h2 {
    color: white;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 5vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
  
  .another-page h3 {
    color: white;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 4vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .another-page h5 {
    color: white;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 4vw;
    margin-top: 2vw;
    margin-bottom: 7vw;
  }

  .link-item5 {
    padding: 0.5vw 1vw;
    background-color: #FFDD28;
    color: black;
    border: none;
    border-radius: 0.5vw;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    text-align: center;
    font-weight: 900;
    font-size: 5vw;
    box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
    border-style: solid;
    border-width: .15vw;
    border-color: black;
    margin-bottom: 0vw;
    margin-top: 3vw;
    width: fit-content;
    align-self: center;
  }

  .text-box4 {
    background-color: rgba(0, 0, 0, .9);
    border-radius: 2vw;
    transition: transform 0.3s;
    width: fit-content;
    border: black;
    border-style: solid;
    border-width: .3vw;
    box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1vw;
    margin-bottom: 30vw;
    margin-top: 0vw;
  }
  
  .text-box4 {
    background-color: rgba(0, 0, 0, .8);
    border-radius: 2vw;
    transition: transform 0.3s;
    width: fit-content;
    border: black;
    border-style: solid;
    border-width: .3vw;
    box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3vw;
    margin-bottom: 30vw;
    margin-top: 0vw;
  }
  
  .text-box3 h3 {
    margin-bottom: 1vw;
    margin-top: 0vw;
    font-size: 7vw;
    color: #A0CEFF;
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  
  .gamepreview {
    width: 75vw;
    height: auto;
    border-radius: 2vw;
    margin-top: 2vw;
  }

  .text-section2 {
    width: 100vw;
    display: flex;
    gap: 3vw;
    align-items: center;
    padding: 1vw;
    align-items: center;
    height: 100vh;
    justify-content: top;
    margin-top: 50vw;
    flex-direction: column;
  }
  
}
