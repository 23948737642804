.navbar {
  background: black;
  padding: 1.2vw;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yoloasfd {
  width: 100vw;
}

.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex: 1;
}

.navbar li {
  margin: 0 1rem;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  font-size: 1.2vw;
 
}

.navbar a:hover {
  color: #f06;
}

.logo {
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
}

.logo img {
  height: 3rem; /* Adjust the height as needed */
}

.navbar2 {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbar-ul {
    display: none;
  }

  .navbar-li {
    display: none;
  }
  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: none;
  }

  .navbar2 {
    display:flex;
    background-color: rgba(0, 0, 0, 0.95);
    height: 15vw;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  

  .navbar2-links2 {
    display: none;
  }
  
  .logo {
    width: 15vw;
    position: fixed;
    top: 0;
    left: 0;
  }

  .navbar2-links2.open {
    display:flex;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    opacity: 1;
    position: absolute;
    top: 100%;
    text-align: center;
    text-decoration: none !;
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  }

  .linkstitle {
    font-size: 8vw;
    margin-top: 0vw;
    margin-bottom: 0vw;
    color: white;
    font-weight: 800;
    box-shadow: 0.2vw 0.2vw 0.2vw 0.2vw rgba(0.95, 0.95, 0.95, 0.95);
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    border-style: solid;
    border-color: black;
    border-radius: 1px;
    
    
  }

  .navbar2-links2.open a {
    color: black;
    font-size: 7vw;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 6vh;
    margin-top: 8vw;
    background-color: #FFDD28;
    background-size:cover;
    border-radius: 9999px;
    box-shadow: 0.2vw 0.2vw 0.2vw 0.2vw rgba(0.5, 0.5, 0.5, 0.5);
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    border-style: solid;
    border-color: black;
  }

  .navbar2-links2.open a:hover {
    background-color: #f06;
  }

  .menu-toggle2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .menu-toggle2 .bar {
    height: 1vw;
    width: 11vw;
    background-color: white;
    transition: 0.3s;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 3vw;
    margin-top: 4vw;
  }

  .menu-toggle2 .bar2 {
    height: 1vw;
    width: 11vw;
    background-color: white;
    transition: 0.3s;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 3vw;
    margin-top: 7vw;
  }

  .menu-toggle2 .bar3{
    height: 1vw;
    width: 11vw;
    background-color: white;
    transition: 0.3s;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 3vw;
    margin-top: 10vw;
  }
  .menu-toggle2 .bar4{
    height: 1vw;
    width: 11vw;
    background-color: white;
    transition: 0.3s;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 3vw;
    margin-top: 13vw;
  }

}