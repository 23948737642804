.contact-page {
  padding: 2vw;
  color: white;
  margin: auto;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 99px;
  width: 70vw;
  border: black;
  height: 70vh;
}

.homewrapper1 {
  position: relative;
  background: linear-gradient(270deg, #FFDD28, #3b1818);
  background-size: 100% 100%;
  width: 100vw;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.homewrapper1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: url('/public/oinkpattern6.png') repeat;
  background-size: 25vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

.contpage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 80vh;
  width: 90vw;
  gap: 1vw;
  background: url('/public/background.png');
  background: rgba(0, 0, 0, 0.75);
  background-repeat: repeat;
  background-size: 20vw;
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
}

.contact-page h2 {
  margin-bottom: 0vw;
  text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
  font-size: 3vw;
  margin-top: 0vw;
  color: #A0CEFF;
}

.contact-page h3 {
  margin-bottom: 1vw;
  width: 50vw;
  text-align: center;
  margin-top: 1vw;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  font-size: 1.5vw;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  height: auto;
  margin-top: 0vw;
}

.form-group {
  margin-bottom: 1vw;
  width: 40vw;
  margin: auto;
}

.form-group label {
  display: block;
  margin-bottom: 0.5vw;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  font-size: 1.25vw;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1vw;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 0.5vw;
  box-sizing: border-box;
  
}

button {
  padding: 0.5vw 1vw;
  background-color: #FFDD28;
  font-size: 1.5vw;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 0vw;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  border-style: solid;
  border-width: .15vw;
  border-color: black;
}

button:hover {
  background-color: #f06;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .contact-page {
    width: 100vw;
    height: fit-content;
    border-radius: 2vw;
  }

  .contact-form {
    width: 80vw;
    height: fit-content;
  }

  .form-group {
    width: 70vw;
  }

  .contact-page h2 {
    margin-bottom: 1vw;
    text-align: center;
    font-size: 12vw;
  }

  .contact-page h3 {
    width: 85vw;
    font-size: 4vw;
    margin-bottom: 5vw;
  }

  .form-group label {
    margin-bottom: 1vw;
    font-size: 5vw;
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  .form-group input,
  .form-group textarea {
    padding: 2vw;
    margin-bottom: 1vw;
  }

  button {
    padding: 1vw 2vw;
    font-size: 6vw;
  }

  .contpage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: white;
    border-radius: 1vw;
    box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
    padding: 1vw;
    height: auto;
    width: 100vw;
    gap: 1vw;
    background: url('/public/background.png');
    background: rgba(0, 0, 0, 0.8);
    background-repeat: repeat;
    background-size: 20vw;
    transition: opacity 1s ease-in-out;
    border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
    z-index: 2;
  }
}