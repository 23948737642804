.donation-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.donation-button-container input {
    width: 15vw;
    padding: 1vw;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-bottom: 0.5vw;
    box-sizing: border-box;
    margin-top: 3vw;
    text-align: center;
    font-weight: 600;
    font-size: 1.1vw;
}

.txlink {
    margin-top: 1vw;
    padding: 0.5vw 1vw;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 1.5vw;
    text-decoration: none;
    box-shadow: none;
    width: fit-content;
    align-self: center;
  }

.donation-button-container button {
    padding: 0.5vw 1vw;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-size: 1.3vw;
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    font-weight: 600;
}

.donation-button-container button:hover {
    background-color: #f06;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 2vw;
    border-radius: 10px;
    text-align: center;
    text-wrap: wrap;
    width: 45vw;
    display: flex;
    flex-direction: column;
}

.modal-content h1 {
    color: #A0CEFF;
    font-size: 4vw;
    margin-bottom: .5vw;
    margin-top: 0vw;
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    text-align: center;
}

.modal-content h2 {
    color: #A0CEFF;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 1.5vw;
}

.modal-content h4 {
    color: black;
    text-align: center;
    font-size: 1.2vw;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.2);
}

.modal-content button {
    margin-top: 1vw;
    padding: 0.5vw 1vw;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 1.4vw;
    text-decoration: none;
    box-shadow: none;
    font-weight: 100;
    width: fit-content;
    align-self: center;
}

.modal-content button:hover {
    background-color: #f06;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .donation-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .donation-button-container input {
        margin-bottom: 7vw;
        margin-top: 5vw;
        width: 75vw;
        padding: 1vw;
        border-radius: 5px;
        border: none;
        outline: none;
        box-sizing: border-box;
        text-align: center;
        font-weight: 600;
        font-size: 5vw;
    }
    
    .donation-button-container button {
        padding: 0.5vw 1vw;
        background-color: #4a90e2;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        font-size: 6vw;
        text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
        font-weight: 600;
        margin-bottom: 5vw;
    }
    
    .donation-button-container button:hover {
        background-color: #f06;
    }

    .txlink :hover {
        background-color: #f06;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .modal-content {
        background-color: #fff;
        padding: 2vw;
        border-radius: 10px;
        text-align: center;
        text-wrap: wrap;
        width: 80vw;
        display: flex;
        flex-direction: column;
    }
    
    .modal-content h1 {
        color: #A0CEFF;
        font-size: 8vw;
        margin-bottom: .5vw;
        text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
        text-align: center;
    }

    .modal-content h2 {
        color: #A0CEFF;
        text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
        text-align: center;
        font-size: 1.5vw;
    }
    
    .modal-content h4 {
        color: black;
        text-align: center;
        font-size: 3vw;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    
    .modal-content button {
        margin-top: 1vw;
        padding: 0.5vw 1vw;
        background-color: #4a90e2;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        text-align: center;
        font-size: 4vw;
        text-decoration: none;
        box-shadow: none;
        font-weight: 100;
        margin-top: 3vw;
        width: fit-content;
        align-self: center;
    }
    
    .modal-content button:hover {
        background-color: #f06;
    }

    .txlink {
        margin-top: 1vw;
        padding: 0.5vw 1vw;
        background-color: #4a90e2;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6);
        text-align: center;
        font-size: 5vw;
        text-decoration: none;
        width: fit-content;
        margin: auto;
      }

    
}