.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background:url('/public/oinkpattern6.png') repeat;
    background-size: 25vw;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modall {
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
  }
  
  .modal p {
    margin-bottom: 50px;
    align-self: center;
    color: white;
    font-weight: 600;
  }
  
  .modal button {
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal button:hover {
    background: #0056b3;
  }

  