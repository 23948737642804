.chart-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    width: 100vw;
    position: fixed;
  }

  .chart-page2 {
    display:none;
  }
  
  #dexscreener-embed {
    width: 100%;
    height: 100%; /* Full height of the chart page */
  }
  
  #dexscreener-embed iframe {
    width: 100vw;
    height: 93.5vh;
    border: none;
  }

  @media (max-width: 768px) {
    .chart-page {
      display: none;
    }

    .chart-page2 {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100%; /* Adjusted to account for the navbar */
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(0, 0, 0);
      color: white;
      position: absolute;
      top: 15vw;
      
    }

    #dexscreener-embed {
      width: 100%;
      height: 100%; /* Full height of the chart page */
      position: relative;
    }
    
    #dexscreener-embed iframe {
      width: 100%;
      height: 90%;
      border: none;
    }
  }
  