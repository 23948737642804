.proposal-submission {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    border-radius: 1vw;
    box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
    padding: 20px;
    height: fit-content;
    margin: 0px;
    width: 50vw;
    background-color: rgba(0, 0, 0, .8);
    transition: opacity 1s ease-in-out;
    border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
    z-index: 0;
  }
  
  .proposal-submission h2 {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .proposal-submission p {
    margin-top: 10px;
  }
  
  .proposal-submission form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }
  
  .proposal-submission input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: auto;
  }

  .proposal-submission textarea {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 20vw;
  }
  
  .proposal-submission button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
  }

  .subwrapper {
    position: relative;
    background: linear-gradient(270deg, #FFDD28, #3b1818);
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;
    align-items: top;
    display: flex;
    justify-content: center;
    z-index: 100;
    margin-bottom: 0vw;
    box-sizing: border-box;
    padding: 2vw;
    overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
  }
  
  .subwrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url('/public/oinkpattern6.png') repeat;
    background-size: 25vw;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the background image is behind other content */
  }

  @media (max-width: 768px) {
    .proposal-submission {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: white;
      border-radius: 1vw;
      box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
      padding: 20px;
      height: fit-content;
      margin-top: 10%;
      width: 90vw;
      background-color: rgba(0, 0, 0, .8);
      transition: opacity 1s ease-in-out;
      border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
      z-index: 0;
    }

    .proposal-submission p {
      margin-top: 0px;
    }
    
    .proposal-submission h2 {
      margin-bottom: 10px;
      margin-top: 0px;
    }
    
    .proposal-submission form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
    }
    
    .proposal-submission input {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 16px;
      width: 95%;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: auto;
    }
  
    .proposal-submission textarea {
      margin-bottom: 10px;
      padding: 10px;
      font-size: 16px;
      width: 95%;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 50vw;
    }
    
    .proposal-submission button {
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
    }
  
    .subwrapper {
      position: relative;
      background: linear-gradient(270deg, #FFDD28, #3b1818);
      background-size: 100% 100%;
      width: 100vw;
      height: 100vh;
      align-items: top;
      display: flex;
      justify-content: center;
      z-index: 100;
      margin-bottom: 0vw;
      box-sizing: border-box;
      padding: 2vw;
      overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
    }
    
    .subwrapper::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: url('/public/oinkpattern6.png') repeat;
      background-size: 25vw;
      opacity: 0.5; /* Adjust the opacity as needed */
      z-index: -1; /* Ensure the background image is behind other content */
    }
  }